import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <a href="https://andymatuschak.org/">Andy Matuschak&#39;s website</a>
    <p>{`I also discovered Andy Matuschak`}{`'`}{`s website! The only expression
which I can give is `}{`"`}{`Why didn`}{`'`}{`t I discover this before!!`}{`"`}</p>
    <p>{`The concept of evergreen notes has been phenomenal to me. I will share
my experience in using them in a future article.`}</p>
    <p>{`The website is also of such a great design!`}</p>
    <p>{`Tweets`}</p>
    <blockquote className="twitter-tweet">
  <p lang="en" dir="ltr">
    It has the beauty of a blank page inviting you to write, to think, to
    create. But that page becomes part of a multitude of pages, a polyphonic
    composition rich with echoes, resonances, interactions and harmonies.
  </p>
  &mdash; David Langtry (@LangtryDavidSQ) <a href="https://twitter.com/LangtryDavidSQ/status/1299855172877922306?ref_src=twsrc%5Etfw">August 29, 2020</a>
    </blockquote>
    <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <p>{`Articles`}</p>
    <a href="https://www.jenvermet.com/ship-it/difference-between-being-a-reader-and-literate">
  Being literate ≠ being a reader — Jen Vermet
    </a>
    <p>{`Here are my highlights from the article`}</p>
    <p>{`School teaches kids how to become literate. It does not teach them
how to become readers. In reality, school traumatizes readers.`}</p>
    <p>{`Ironically, the same institution that is supposed to inspire kids to
become curious about education creates the opposite effect.`}</p>
    <p>{`As Gustave Flaubert shared in 1867, “Do not read, as children do, to
amuse yourself, or like the ambitious, for the purpose of
instruction. No, read in order to live.”`}</p>
    <p>{`Naval Ravikant`}{`'`}{`s practical theory for reading is to see how many
ideas he can understand and use. It is better to reread the best 100
books and know those ideas really well than read all the books.`}</p>
    <p>{`Spark the fire for your love of reading even if school took that
away. Afterall, reading is the closest humanity has to time-travel.`}</p>
    <p>{`Miscellaneous`}</p>
    <a href="https://educationonair.withgoogle.com/events/anywhereschool2020/schedule">
  The Anywhere School 2020 by Google
    </a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      